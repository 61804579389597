import { Duplexer } from '@wix/duplexer-js';

let duplexerClient;
export default function createDuplexerConnection({
  duplexerSocketsServerUrl,
  getInstance,
  appDefId,
  useHttpStreaming,
}) {
  if (duplexerClient) {
    try {
      duplexerClient.connection.close();
    } catch (error) {}
  }

  duplexerClient = new Duplexer(duplexerSocketsServerUrl, {
    instanceUpdater: { getInstance },
    _experimentalHttpStreaming: useHttpStreaming,
  });
  return duplexerClient.connect({ appDefId });
}
